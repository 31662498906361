import { useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import InputBox from "../Inputbox";
import Spacer from "../Spacer";

const TotalPiecesSoldForm = ({ unitsPerMonth, active, onSubmit }) => {
  const nf = new Intl.NumberFormat("en-US");

  //Total Pieces Sold
  const [currentAverage, setCurrentAverage] = useState(0);
  const [projectedAverage, setProjectedAverage] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const [disable, setDisable] = useState(!active);

  useEffect(() => {
    setDisable(!active);
  }, [active]);

  const getROC = (projected, current) => {
    return (projected / current - Math.floor(projected / current)) * 100;
  };

  const onCalculate = () => {
    setVisibility(true);
    setDisable(true);
    const currentTotal = currentAverage * unitsPerMonth.current.total;
    const projectedTotal = projectedAverage * unitsPerMonth.projected.total;

    const piecesSold = {
      current: {
        unitsPerMonth: unitsPerMonth.current.total,
        averageUnits: currentAverage,
        total: currentTotal,
      },
      projected: {
        unitsPerMonth: unitsPerMonth.projected.total,
        averageUnits: projectedAverage,
        total: projectedTotal,
      },
      roc: {
        averageUnits: getROC(projectedAverage, currentAverage),
        total: getROC(projectedTotal, currentTotal),
      },
    };

    onSubmit(piecesSold);
  };

  return (
    <div className="container animate__animated animate__fadeInDown">
      <div className="card">
        <h5 className="card-header">
          Step 2. Calculate Your Total Pieces Sold
        </h5>
        <div className="card-body">
          <div className="row">
            <div className="col-lg">
              <h5 className="card-title">CURRENT</h5>
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <div className="col-sm">
                        <b>Units Per Month</b>
                        <input
                          className="form-control"
                          type="text"
                          value={nf.format(
                            unitsPerMonth.current.total.toFixed(2)
                          )}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="col-sm-1 py-3 d-flex justify-content-center align-items-end">
                        <i class="fa-solid fa-xmark h-50"></i>
                      </div>
                      <div className="col-sm">
                        <b>Average Units per Main Piece Sold</b>
                        <InputBox
                          onChange={setCurrentAverage}
                          disable={disable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer />
              <h5 className="card-title">PROJECTED</h5>
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <div className="col-sm">
                        <b>Units Per Month</b>
                        <input
                          className="form-control"
                          type="text"
                          value={nf.format(
                            unitsPerMonth.projected.total.toFixed(2)
                          )}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="col-sm-1 py-3 d-flex justify-content-center align-items-end">
                        <i className="fa-solid fa-xmark h-50"></i>
                      </div>
                      <div className="col-sm">
                        <b>Average Units per Main Piece Sold</b>
                        <InputBox
                          onChange={setProjectedAverage}
                          disable={disable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer />
            </div>
            <div className="col-lg">
              <div className="container">
                <h4>Notes:</h4>
                <p>
                  <b>UNITS PER MONTH</b> x{" "}
                  <b>AVERAGE UNITS PER MAIN PIECE SOLD </b> ={" "}
                  <b>TOTAL PIECES SOLD</b>
                </p>
                <p>
                  Provide both your <b>current</b> and <b>projected</b> average
                  units per main pieces sold value
                </p>
                <button
                  disabled={visibility}
                  className="btn btn-outline-primary"
                  onClick={onCalculate}
                >
                  <i className="fa-solid fa-calculator"></i>
                  <span className="mx-2">Calculate</span>
                </button>
                <Spacer />
              </div>
              <div
                className="container animate__animated animate__fadeInDown"
                style={{ display: visibility ? "block" : "none" }}
              >
                <hr />
                <div className="row">
                  <div className="col">
                    <p className="fw-bold">Total Pieces Sold</p>
                    <p>
                      Current:{" "}
                      <span className="fw-bold" id="current-units">
                        {nf.format(
                          (
                            currentAverage * unitsPerMonth.current.total
                          ).toFixed(0)
                        )}
                      </span>
                    </p>
                    <p>
                      Projected:{" "}
                      <span className="fw-bold" id="projected-units">
                        {nf.format(
                          (
                            projectedAverage * unitsPerMonth.projected.total
                          ).toFixed(0)
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="col">
                    <p className="fw-bold">Rate of Change:</p>
                    <p>
                      Average Units Per Main Piece Sold:{" "}
                      <span className="fw-bold">
                        {getROC(projectedAverage, currentAverage).toFixed(1)}%
                      </span>
                    </p>
                    <p>
                      Total Pieces Sold:{" "}
                      <span className="fw-bold">
                        {getROC(
                          projectedAverage * unitsPerMonth.projected.total,
                          currentAverage * unitsPerMonth.current.total
                        ).toFixed(1)}
                        %
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalPiecesSoldForm;
