import { useState } from "react";
import InputBox from "../Inputbox";
import Spacer from "../Spacer";

const UnitsPerMonthForm = ({ onSubmit }) => {
  const nf = new Intl.NumberFormat("en-US");
  const [currentPlacement, setCurrentPlacement] = useState(0);
  const [currentVelocity, setCurrentVelocity] = useState(0);
  const [projectedPlacement, setProjectedPlacement] = useState(0);
  const [projectedVelocity, setProjectedVelocity] = useState(0);
  const [visibility, setVisibility] = useState(false);

  const getROC = (projected, current) => {
    return (projected / current - Math.floor(projected / current)) * 100;
  };

  const onCalculate = () => {
    setVisibility(true);
    const currentTotal = currentPlacement * currentVelocity;
    const projectedTotal = projectedPlacement * projectedVelocity;

    const unitsPerMonth = {
      current: {
        placement: currentPlacement,
        velocity: currentVelocity,
        total: currentTotal,
      },
      projected: {
        placement: projectedPlacement,
        velocity: projectedVelocity,
        total: projectedTotal,
      },
      roc: {
        placement: getROC(projectedPlacement, currentPlacement),
        velocity: getROC(projectedVelocity, currentVelocity),
        total: getROC(projectedTotal, currentTotal),
      },
    };

    onSubmit(unitsPerMonth);
  };

  return (
    <div className="container">
      <div className="card">
        <h5 className="card-header">Step 1. Calculate Your Units Per Month</h5>
        <div className="card-body">
          <div className="row">
            <div className="col-lg">
              <h5 className="card-title">CURRENT</h5>
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <div className="col-sm">
                        <b>Placement</b>
                        <InputBox
                          onChange={setCurrentPlacement}
                          disable={visibility}
                        />
                      </div>
                      <div className="col-sm-1 py-3 d-flex justify-content-center align-items-end">
                        <i class="fa-solid fa-xmark h-50"></i>
                      </div>
                      <div className="col-sm">
                        <b>Velocity</b>
                        <InputBox
                          onChange={setCurrentVelocity}
                          disable={visibility}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer />
              <h5 className="card-title">PROJECTED</h5>
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <div className="col-sm">
                        <b>Placement</b>
                        <InputBox
                          onChange={setProjectedPlacement}
                          disable={visibility}
                        />
                      </div>
                      <div className="col-sm-1 py-3 d-flex justify-content-center align-items-end">
                        <i class="fa-solid fa-xmark h-50"></i>
                      </div>
                      <div className="col-sm">
                        <b>Velocity</b>
                        <InputBox
                          onChange={setProjectedVelocity}
                          disable={visibility}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer />
            </div>
            <div className="col-lg">
              <div className="container">
                <h4>Notes:</h4>
                <p>
                  Formula: <b>PLACEMENTS</b> x <b>VELOCITY</b> ={" "}
                  <b>UNITS per MONTH</b>
                </p>
                <p>
                  Provide both your <b>current</b> and <b>projected</b>{" "}
                  placements/velocity multiplier
                </p>
                <button
                  disabled={visibility}
                  className="btn btn-outline-primary"
                  onClick={onCalculate}
                >
                  <i className="fa-solid fa-calculator"></i>
                  <span className="mx-2">Calculate</span>
                </button>
              </div>
              <Spacer />
              <div
                className="container animate__animated animate__fadeInDown"
                style={{ display: visibility ? "block" : "none" }}
              >
                <hr />
                <div className="row">
                  <div className="col">
                    <p className="fw-bold">Units Per Month</p>
                    <p>
                      Current:{" "}
                      <span className="fw-bold" id="current-units">
                        {nf.format(
                          (currentPlacement * currentVelocity).toFixed(2)
                        )}
                      </span>
                    </p>
                    <p>
                      Projected:{" "}
                      <span className="fw-bold" id="projected-units">
                        {nf.format(
                          (projectedPlacement * projectedVelocity).toFixed(2)
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="col">
                    <p className="fw-bold">Rate of Change:</p>
                    <p>
                      Placements:{" "}
                      <span className="fw-bold">
                        {getROC(projectedPlacement, currentPlacement).toFixed(
                          1
                        )}
                        %
                      </span>
                    </p>
                    <p>
                      Velocity:{" "}
                      <span className="fw-bold">
                        {getROC(projectedVelocity, currentVelocity).toFixed(1)}%
                      </span>
                    </p>
                    <p>
                      Units Per Month:{" "}
                      <span className="fw-bold">
                        {getROC(
                          projectedPlacement * projectedVelocity,
                          currentPlacement * currentVelocity
                        ).toFixed(1)}
                        %
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitsPerMonthForm;
