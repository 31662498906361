import Spacer from "../Spacer";

const AnnualSalesForm = ({ data }) => {
  const nf = new Intl.NumberFormat("en-US");

  const getDifference = (multiplier) => {
    const current = data.salesPerMonth.current.total * multiplier;
    const projected = data.salesPerMonth.projected.total * multiplier;
    return projected - current;
  };

  const getROC = (multiplier) => {
    const current = data.salesPerMonth.current.total * multiplier;
    const projected = data.salesPerMonth.projected.total * multiplier;
    return (projected / current - Math.floor(projected / current)) * 100;
  };

  return (
    <div className="container animate__animated animate__fadeInDown">
      <div className="card">
        <h5 className="card-header">Result: Your Annual Sales</h5>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <h5 className="card-title text-start">CURRENT</h5>
              <Spacer />
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <p className="fw-bold">By Quarterly Extension:</p>
                    <p>
                      Formula:{" "}
                      <span className="fw-bold">
                        Total Sales Per Month x 3 Months
                      </span>
                    </p>
                    <p>
                      Total Sales Per Month:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(data.salesPerMonth.current.total.toFixed(0))}
                      </span>
                    </p>
                    <p>
                      Months Per Year: <span className="fw-bold">3</span>
                    </p>
                    <p>
                      Annual Sales:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(
                          (3 * data.salesPerMonth.current.total).toFixed(0)
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <h5 className="card-title text-start">PROJECTED</h5>
              <Spacer />
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <p className="fw-bold">By Quarterly Extension:</p>
                    <p>
                      Formula:{" "}
                      <span className="fw-bold">
                        Total Sales Per Month x 3 Months
                      </span>
                    </p>
                    <p>
                      Total Sales Per Month:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(
                          data.salesPerMonth.projected.total.toFixed(0)
                        )}
                      </span>
                    </p>
                    <p>
                      Months Per Year: <span className="fw-bold">3</span>
                    </p>
                    <p>
                      Annual Sales:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(
                          (3 * data.salesPerMonth.projected.total).toFixed(0)
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Spacer />
          <div className="row">
            <div className="container">
              <p className="fw-bold">Rate of Change: {getROC(3).toFixed(1)}%</p>
              <p className="fw-bold">
                Dollar Difference: ${nf.format(getDifference(3).toFixed(0))}
              </p>
            </div>
          </div>
          <Spacer />
          <div className="row">
            <div className="col">
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <p className="fw-bold">By Annual Extension:</p>
                    <p>
                      Formula:{" "}
                      <span className="fw-bold">
                        Total Sales Per Month x 12 Months
                      </span>
                    </p>
                    <p>
                      Total Sales Per Month:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(data.salesPerMonth.current.total.toFixed(0))}
                      </span>
                    </p>
                    <p>
                      Months Per Year: <span className="fw-bold">12</span>
                    </p>
                    <p>
                      Annual Sales:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(
                          (12 * data.salesPerMonth.current.total).toFixed(0)
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <p className="fw-bold">By Annual Extension:</p>
                    <p>
                      Formula:{" "}
                      <span className="fw-bold">
                        Total Sales Per Month x 12 Months
                      </span>
                    </p>
                    <p>
                      Total Sales Per Month:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(
                          data.salesPerMonth.projected.total.toFixed(0)
                        )}
                      </span>
                    </p>
                    <p>
                      Months Per Year: <span className="fw-bold">12</span>
                    </p>
                    <p>
                      Annual Sales:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(
                          (12 * data.salesPerMonth.projected.total).toFixed(0)
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Spacer />
          <div className="row">
            <div className="container">
              <p className="fw-bold">
                Rate of Change: {getROC(12).toFixed(1)}%
              </p>
              <p className="fw-bold">
                Dollar Difference: ${nf.format(getDifference(12).toFixed(0))}
              </p>
            </div>
          </div>
          <div className="container d-flex justify-content-end align-items-center">
            <button
              className="btn btn-primary"
              onClick={() => window.location.reload()}
            >
              <i class="fa-solid fa-arrows-rotate"></i>
              <span className="mx-2">Restart</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualSalesForm;
