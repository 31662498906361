const InputBox = ({ onChange, disable }) => (
  <input
    type="number"
    className="form-control"
    disabled={disable}
    readOnly={disable}
    onChange={(e) => onChange(e.target.value)}
  />
);

export default InputBox;
