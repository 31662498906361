import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Spacer from "../components/Spacer";
import UnitsPerMonthForm from "../components/forms/UnitsPerMonthForm";
import TotalPiecesSoldForm from "../components/forms/TotalPiecesSoldForm";
import TotalSalesPerMonthForm from "../components/forms/TotalSalesPerMonthForm";
import AnnualSalesForm from "../components/forms/AnnualSalesForm";
import Footer from "../components/Footer";

const Home = () => {
  //Groups
  const [unitsPerMonth, setUnitsPerMonth] = useState({
    current: {
      placement: 0,
      velocity: 0,
      total: 0,
    },
    projected: {
      placement: 0,
      velocity: 0,
      total: 0,
    },
    roc: {
      placement: 0,
      velocity: 0,
      total: 0,
    },
  });
  const [piecesSold, setPiecesSold] = useState({
    current: {
      unitsPerMonth: 0,
      averageUnits: 0,
      total: 0,
    },
    projected: {
      unitsPerMonth: 0,
      averageUnits: 0,
      total: 0,
    },
    roc: {
      averageUnits: 0,
      total: 0,
    },
  });
  const [salesPerMonth, setSalesPerMonth] = useState({
    current: {
      piecesSold: 0,
      averageDollar: 0,
      total: 0,
    },
    projected: {
      piecesSold: 0,
      averageDollar: 0,
      total: 0,
    },
    roc: {
      averageDollar: 0,
      total: 0,
    },
  });

  //Containers
  const [containers, setContainers] = useState({
    units: true,
    pieces: false,
    sales: false,
    total: false,
  });

  const onUnitsPerMonthSubmit = (data) => {
    setUnitsPerMonth(data);
    setContainers((prevState) => ({
      ...prevState,
      pieces: true,
    }));
  };

  const onTotalPiecesSubmit = (data) => {
    setPiecesSold(data);
    setContainers((prevState) => ({
      ...prevState,
      sales: true,
    }));
  };

  const onTotalSalesSubmit = (data) => {
    setSalesPerMonth(data);
    setContainers((prevState) => ({
      ...prevState,
      total: true,
    }));
  };

  return (
    <div className="bg-light">
      <Navbar />
      <Spacer />
      <div className="mx-0 bg-light">
        <UnitsPerMonthForm onSubmit={onUnitsPerMonthSubmit} />
        <Spacer />
        <TotalPiecesSoldForm
          active={containers.pieces}
          unitsPerMonth={unitsPerMonth}
          onSubmit={onTotalPiecesSubmit}
        />
        <Spacer />
        <TotalSalesPerMonthForm
          active={containers.pieces}
          totalPiecesSold={piecesSold}
          onSubmit={onTotalSalesSubmit}
        />
        <Spacer />
        <AnnualSalesForm data={{ unitsPerMonth, piecesSold, salesPerMonth }} />
        <Spacer />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
