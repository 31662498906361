import Logo from "../assets/dovr.png";

const Navbar = () => (
  <nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <h3>
          <span className="brand-heading">SALES CALCULATOR</span>{" "}
          <span className="fs-6 ms-3 text-muted">
            by
            <img
              className="img-thumbnail border-0 bg-light mx-3"
              style={{ width: 100 }}
              src={Logo}
            />
          </span>
        </h3>
      </a>

      <div class="d-flex" role="search">
        <button
          class="btn btn-outline-primary"
          onClick={() => window.location.reload()}
        >
          Reset
        </button>
      </div>
    </div>
  </nav>
);

export default Navbar;
