import DOVRLogo from "../assets/dovr.png";

const Footer = () => (
  <footer class="text-center text-lg-start bg-light text-muted">
    <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      <div class="d-none d-lg-block">
        <span>Get connected with us on social networks:</span>
      </div>

      <div>
        <a
          href="https://www.facebook.com/letsdoVR"
          target="_blank"
          class="me-4 text-reset"
        >
          <i class="fab fa-facebook-f"></i>
        </a>
        <a
          href="https://www.instagram.com/bebrilliant.io/"
          target="_blank"
          class="me-4 text-reset"
        >
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://vm.tiktok.com/ZTdPpMSCY/" class="me-4 text-reset">
          <i class="fa-brands fa-tiktok"></i>
        </a>
        <a href="https://www.dovrmedia.com/" class="me-4 text-reset">
          <i class="fa-solid fa-globe"></i>
        </a>
      </div>
    </section>

    <section class="">
      <div class="container-fluid text-center text-md-start mt-5">
        <div class="row">
          <div class="col-md-8 col-lg-9 col-xl-9 mx-auto mb-4">
            <img
              className="img-thumbnail border-0 bg-light w-25"
              src={DOVRLogo}
            />
            <p className="pt-5 fw-bold">
              Get an estimate of your annual sales within minutes!
            </p>
            <p>v1.2.7</p>
          </div>
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <p>
              <i class="fas fa-home me-3"></i> Newark, NJ 07105, US
            </p>
            <p>
              <i class="fas fa-envelope me-3"></i>
              info@dovrmedia.com
            </p>
            <p>
              <i class="fas fa-phone me-3"></i> (732)-710-2476
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="text-center p-4">
      © {new Date().getFullYear()} Copyright:{" "}
      <a
        class="text-reset  fw-bold text-decoration-none"
        href="https://dovrmedia.com"
      >
        DOVR Media
      </a>
    </div>
  </footer>
);

export default Footer;
