import { useEffect, useState } from "react";
import InputBox from "../Inputbox";
import Spacer from "../Spacer";

const TotalSalesPerMonthForm = ({ totalPiecesSold, active, onSubmit }) => {
  const nf = new Intl.NumberFormat("en-US");

  //Total Pieces Sold
  const [currentAverage, setCurrentAverage] = useState(0);
  const [projectedAverage, setProjectedAverage] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const [disable, setDisable] = useState(!active);

  useEffect(() => {
    setDisable(!active);
  }, [active]);

  const getROC = (projected, current) => {
    return (projected / current - Math.floor(projected / current)) * 100;
  };

  const onCalculate = () => {
    setVisibility(true);
    setDisable(true);
    const currentTotal = currentAverage * totalPiecesSold.current.total;
    const projectedTotal = projectedAverage * totalPiecesSold.projected.total;

    const salesPerMonth = {
      current: {
        piecesSold: totalPiecesSold.current.total,
        averageDollar: currentAverage,
        total: currentTotal,
      },
      projected: {
        piecesSold: totalPiecesSold.projected.total,
        averageDollar: projectedAverage,
        total: projectedTotal,
      },
      roc: {
        averageDollar:
          (projectedAverage / currentAverage -
            Math.floor(projectedAverage / currentAverage)) *
          100,
        total:
          (projectedTotal / currentTotal -
            Math.floor(projectedTotal / currentTotal)) *
          100,
      },
    };

    onSubmit(salesPerMonth);
  };

  return (
    <div className="container animate__animated animate__fadeInDown">
      <div className="card">
        <h5 className="card-header">
          Step 3. Calculate Your Total Sales Per Month
        </h5>
        <div className="card-body">
          <div className="row">
            <div className="col-lg">
              <h5 className="card-title">CURRENT</h5>
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <div className="col-sm">
                        <b>Total Pieces Sold</b>
                        <input
                          className="form-control"
                          type="text"
                          value={nf.format(
                            totalPiecesSold.current.total.toFixed(0)
                          )}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="col-sm-1 py-3 d-flex justify-content-center align-items-end">
                        <i class="fa-solid fa-xmark h-50"></i>
                      </div>
                      <div className="col-sm">
                        <b>Average Dollar Per Piece</b>
                        <InputBox
                          onChange={setCurrentAverage}
                          disable={disable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer />
              <h5 className="card-title">PROJECTED</h5>
              <div className="card-text">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <div className="col-sm">
                        <b>Total Pieces Sold</b>
                        <input
                          className="form-control"
                          type="text"
                          value={nf.format(
                            totalPiecesSold.projected.total.toFixed(0)
                          )}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="col-sm-1 py-3 d-flex justify-content-center align-items-end">
                        <i class="fa-solid fa-xmark h-50"></i>
                      </div>
                      <div className="col-sm">
                        <b>Average Dollar Per Piece</b>
                        <InputBox
                          onChange={setProjectedAverage}
                          disable={disable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer />
            </div>
            <div className="col-lg">
              <div className="container">
                <h4>Notes:</h4>
                <p>
                  <b>TOTAL PIECES SOLD</b> x <b>AVERAGE DOLLAR PER PIECE </b> ={" "}
                  <b>TOTAL SALES PER MONTH</b>
                </p>
                <p>
                  Provide both your <b>current</b> and <b>projected</b> average
                  dollar per piece.
                </p>
                <button
                  disabled={visibility}
                  className="btn btn-outline-primary"
                  onClick={onCalculate}
                >
                  <i className="fa-solid fa-calculator"></i>
                  <span className="mx-2">Calculate</span>
                </button>
                <Spacer />
              </div>
              <div
                className="container animate__animated animate__fadeInDown"
                style={{ display: visibility ? "block" : "none" }}
              >
                <div className="row">
                  <hr />
                  <div className="col">
                    <p className="fw-bold">Total Sales Per Month</p>
                    <p>
                      Current:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(
                          (
                            currentAverage * totalPiecesSold.current.total
                          ).toFixed(0)
                        )}
                      </span>
                    </p>
                    <p>
                      Projected:{" "}
                      <span className="fw-bold">
                        $
                        {nf.format(
                          (
                            projectedAverage * totalPiecesSold.projected.total
                          ).toFixed(0)
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="col">
                    <p className="fw-bold">Rate of Change:</p>
                    <p>
                      Average Dollar Per Piece:{" "}
                      <span className="fw-bold">
                        {getROC(projectedAverage, currentAverage).toFixed(1)}%
                      </span>
                    </p>
                    <p>
                      Total Sales Per Month:{" "}
                      <span className="fw-bold">
                        {getROC(
                          projectedAverage * totalPiecesSold.projected.total,
                          currentAverage * totalPiecesSold.current.total
                        ).toFixed(1)}
                        %
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalSalesPerMonthForm;
